<template>
    <ContentWrapper>
        <breadcrumb v-if="transaction.amount > 0" icon="fad fa-exchange-alt" :name="$t('wallet.view.transaction.heading') + ' ' + transaction.label" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card card-default animated-fast fadeInUp">
                        <div class="card-body p-0">

                        </div>
                        <div class="card-header">
                            {{$t('wallet.view.transaction.details')}}
                        </div>
                        <div class="card-body">
                            <table class="table table-hover">
                                <tbody>
                                <tr>
                                    <td>{{$t('wallet.view.transaction.id')}} </td>
                                    <td>{{transaction.label}}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('wallet.view.transaction.date')}} </td>
                                    <td>{{ $moment.utc(transaction.created_at).local().format('LLL')}}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('wallet.view.transaction.type')}} </td>
                                    <td>{{ $t('wallet.component.transactions.types.' + transaction.type + '.title')}}</td>
                                </tr>
                                <tr>
                                    <td>{{$t('wallet.view.transaction.amount')}}</td>
                                    <td>{{ transaction.amount  | currency}} {{transaction.currency.shorthand}}</td>
                                </tr>
                                </tbody>
                            </table>

                            <b-btn class="mt-5 float-right" :to="{name: 'Wallet'}" variant="primary">{{$t('wallet.view.transaction.back')}} </b-btn>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </ContentWrapper>
</template>
<style lang="scss">

</style>
<script>

    export default {
        data() {
            return {
                transaction: {
                    currency: {
                        id: 0,
                        label: '',
                        shorthand: '',
                        type: '',
                        created_at: '',
                        updated_at: '',
                    },
                    type: '',
                    amount: '',
                    type_label: '',
                    created_at: '',
                    positive: '',
                    label: '',
                    sender: '',
                },
            }
        },
        mounted() {
            this.$api.get('wallet/transactions/' + this.$route.params.transaction_label).then(response => {
                this.transaction = response.data.transaction;
            });
        },
        methods: {}
    }
</script>
